import {
  DrawerInsertPossibilitiesV2,
  IProdboardV2BackPanel,
  IProdboardV2CenterPost,
  IProdboardV2CombinedUnit,
  IProdboardV2CoverSide,
  IProdboardV2Door,
  IProdboardV2DoorAttachment,
  IProdboardV2DoorStyle,
  IProdboardV2Filler,
  IProdboardV2FrameWidth,
  IProdboardV2Hanging,
  IProdboardV2LegsAndSkirting,
  IProdboardV2PaintProcess,
  IProdboardV2PaintSide,
  IProdboardV2Shelves,
  TProdboardV2Drawers,
  TProdboardV2Filling,
  TProdboardV2HandleDoor,
  TProdboardV2Hinges,
  TProdboardV2SpiceRacks
} from './prodboard-file-v2-types'

export interface I2DCoordinates {
  x: number
  y: number
}

export interface I3DCoordinates extends I2DCoordinates {
  z: number
}

export const MillFileItemOptionNames = [
  'backPanel',
  'brassPlate',
  'carpenterJoy',
  'centerPost',
  'combinedUnit',
  'cornice',
  'coverSide',
  'cuttingBoard',
  'door',
  'doorAttachment',
  'doorType',
  'drawerDoor',
  'drawerFront',
  'drawerInsert',
  'fanAdoption',
  'fanExtractorPanel',
  'filler',
  'frameWidth',
  'handleDoor',
  'handleDrawer',
  'hanging',
  'hiddenDrawer',
  'hiddenDrawerSink',
  'hiddenVisibleWidth',
  'hinges',
  'legs',
  'lighting',
  'paintProcess',
  'paintSide',
  'scribings',
  'shelves',
  'shelvesAdjustable',
  'skirting',
  'spiceRack'
] as const
export type TMillFileItemOptionName = typeof MillFileItemOptionNames[number]

/**
 * File created from a Prodboard project's file. This type of file is created
 * at Mill to unify all possible versions that Prodboard can generate.
 * For example, as of today 2024-09-26, Prodboard has two different official
 * versions (V1 & V2) and some old property fields (we call them V0). That's why
 * we will unify these different versions (and all those coming in the future)
 * into a common data-model, MillFile.
 */
export interface IMillFile {
  /**
   * Flag, always true, that identifies this type of file in DB, to be able to
   * differentiate it from old model "ProdboardFile" (2024-10-31).
   * It means "Is Mill File", shortened "isMilf".
   */
  isMilf: true
  /**
   * Prodboard project that this file belongs to.
   */
  prodboardId: string
  /**
   * Prodboard project number. It's linked to project ID in a way that with
   * ID you can identify the project and with number you can identify which
   * version
   */
  prodboardNumber: string
  /**
   * URL to prodboard. It could either redirect you to V1 or V2.
   * Depending on the entry file it will be one domain or other.
   */
  prodboardUrl: string
  /**
   * Customer linked to project file. It should come from Prodboard JSON, but
   * if not, we do set a default value?
   */
  customer: {
    name: string
    email?: string
    phone?: string
  }
  /**
   * File's plan, which includes all the items (cabinets) and room
   */
  plan: IMillFilePlan
}

/**
 * File's plan. Which defines items involve in the Prodboard project and the
 * room (basically used for dimension-limitation checks) in which the
 * kitchen is being created.
 */
export interface IMillFilePlan {
  /**
   * Items/cabinets involved in the project.
   */
  items: IMillFileItem[]
  /**
   * Room in which the kitchen is being created
   */
  room: IMillRoom
}

export interface IMillRoom {
  /**
   * Dimensions of the original room
   */
  dimensions: I3DCoordinates
  /**
   * Possible beams/columns in the room that will limit the available
   * space of the room.
   */
  beams: TMillRoomBeam[]
}

export type TMillRoomBeam = Pick<IMillFileItem, 'uuid' | 'code' | 'position' | 'dimensions'>

/**
 * Every item of file's plan. We usually refer to these items as Cabinets.
 * All items/cabinets will have multiple options that will contain all
 * customizations done to the cabinet.
 */
export interface IMillFileItem {
  /**
   * Unique ID to identify the item/cabinet inside the project.
   */
  uuid: string
  /**
   * Item's index inside the kitchen.
   */
  index: number
  /**
   * Item's code that is linked to a Product in our DB. It's basically the
   * cabinet's model and its variant. For example, OD3x2_121
   */
  code: string
  /**
   * Possible options/customisation that this item (cabinet) can have.
   */
  options: IMillItemOptions
  /**
   * Base dimensions of the cabinet.
   * Sometimes they are not the real dimensions of the cabinet since some
   * options will make the cabinet to be bigger.
   */
  dimensions: I3DCoordinates
  /**
   * Position of the item inside the room. It is composed by "center" and
   * "direction". Center indicates where is the room the item is, and
   * direction indicates where it's looking at.
   */
  position: IMillItemPosition
  /**
   * Comment on the cabinet that has been set in Prodboard. Even if not truly
   * set in PB, we will set it for the MillFile, but with "deleted" flag ON.
   * Summarising, all items (cabinets) will have a PB comment.
   */
  prodboardComment: {
    /**
     * An ID that is formed by hashing the original comment, so we can ensure
     * that it hasn't been changed.
     */
    id: string
    /**
     * Comment string
     */
    comment: string
    /**
     * Flag that covers a Mill need. If it is already imported or not into Mill.
     */
    deleted: boolean
  }
}

/**
 * Position of the item inside the room. It is composed by "center" and
 * "direction". Center indicates where is the room the item is, and
 * direction indicates where it's looking at.
 */
export interface IMillItemPosition {
  /**
   * 3D coordinates position inside the room.
   */
  center: I3DCoordinates
  /**
   * Object composed as 3D coordinates, but only one of the axis will have
   * a "1". For example: {x:1, y:0, z:0}. This "1" indicates where the
   * item is facing.
   */
  direction: I3DCoordinates
}

/**
 * All Mill-item possible options. All of them are optional since different
 * type of cabinets (items) will have some properties but not others. They
 * vary a lot.
 * All properties of this interface must be like:
 * Record<TMillFileItemOptionName, T>, where T is the correspondent Mill type
 * of the option name. For example, backPanel's T will be BackPanelMill.
 */
export interface IMillItemOptions extends Partial<Record<TMillFileItemOptionName, TMillOption>> {
  backPanel?: BackPanelMill
  brassPlate?: BrassPlateMill
  carpenterJoy?: CarpenterJoyMill
  centerPost?: CenterPostMill
  combinedUnit?: CombinedUnitMill
  cornice?: CorniceMill
  coverSide?: CoverSideMill
  cuttingBoard?: CuttingBoardMill
  door?: DoorMill
  doorAttachment?: DoorAttachmentMill
  doorType?: DoorTypeMill
  drawerDoor?: DrawerDoorMill
  drawerFront?: DrawerFrontMill
  drawerInsert?: DrawerInsertMill
  fanAdoption?: FanAdoptionMill
  fanExtractorPanel?: FanExtractorPanelMill
  filler?: FillerMill
  frameWidth?: FrameWidthMill
  handleDoor?: HandleDoorMill
  handleDrawer?: HandleDrawerMill
  hanging?: HangingMill
  hiddenDrawer?: HiddenDrawerMill
  hiddenDrawerSink?: HiddenDrawerSinkMill
  hiddenVisibleWidth?: HiddenVisibleWidthMill
  hinges?: HingesMill
  legs?: LegMill
  lighting?: LightingMill
  paintProcess?: PaintProcessMill
  paintSide?: PaintSideMill
  // 'plinth' option is not in file, it is created in Mill
  scribings?: ScribingsMill
  shelves?: ShelvesMill
  shelvesAdjustable?: ShelvesAdjustableMill
  skirting?: SkirtingMill
  spiceRack?: SpiceRackMill
}

/**
 * Type that gathers all possible classes of MillOption. It might be useful
 * when creating some generic methods.
 */
export type TMillOption = FrameWidthMill |
  FillerMill |
  LegMill |
  HandleDoorMill |
  HiddenVisibleWidthMill |
  FanAdoptionMill |
  BrassPlateMill |
  CombinedUnitMill |
  DoorTypeMill |
  HingesMill |
  HandleDrawerMill |
  ShelvesMill |
  SpiceRackMill |
  HangingMill |
  LightingMill |
  CorniceMill |
  CenterPostMill |
  PaintProcessMill |
  DrawerFrontMill |
  CarpenterJoyMill |
  SkirtingMill |
  ShelvesAdjustableMill |
  DoorMill |
  DoorAttachmentMill |
  ScribingsMill |
  CoverSideMill |
  BackPanelMill |
  HiddenDrawerSinkMill |
  CuttingBoardMill |
  DrawerDoorMill |
  PaintSideMill |
  FanExtractorPanelMill |
  DrawerInsertMill |
  HiddenDrawerMill

/**
 * Space in between the edges of the cabinet and the doors. For example:
 *  ┌─────────────┐ ─┐ <-- Top of cabinet
 *  │             │  │ <-- Top frame width
 *  │  ┌───────┐  │ ─┘ <-- Beginning of door
 *  │  │       │  │
 *  │  │       │  │
 *  │  │     * │  │
 *  │  └───────┘  │
 *  └─────────────┘
 */
export class FrameWidthMill {
  top: number
  left: number
  right: number
  bottom: number

  constructor(obj: FrameWidthMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): FrameWidthMill {
    return new FrameWidthMill({
      top: input.value?.top ?? 0,
      left: input.value?.left ?? 0,
      right: input.value?.right ?? 0,
      bottom: input.value?.bottom ?? 0
    })
  }

  public static fromV2(input: IProdboardV2FrameWidth): FrameWidthMill {
    return new FrameWidthMill({
      top: input.top ?? 0,
      left: input.left ?? 0,
      right: input.right ?? 0,
      bottom: input.bottom ?? 0
    })
  }
}

export class FillerMill {
  top: number
  left: number
  right: number
  bottom: number

  constructor(obj: FillerMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): FillerMill {
    return new FillerMill({
      top: input.value?.top ?? 0,
      left: input.value?.left ?? 0,
      right: input.value?.right ?? 0,
      bottom: input.value?.bottom ?? 0
    })
  }

  public static fromV2(input: IProdboardV2Filler): FillerMill {
    return new FillerMill({
      top: input.top ?? 0,
      left: input.left ?? 0,
      right: input.right ?? 0,
      bottom: input.bottom ?? 0
    })
  }
}

/**
 * Sometime, standing cabinets need a leg, two, or none. Depending on their
 * positioning in the kitchen. Those in the middle usually don't need them.
 * But those on the sides or alone might need some legs.
 */
export class LegMill {
  value: typeof LegMill.values[number]

  public static readonly values =
    ['Inga', 'Vänster', 'Höger', 'Båda'] as const

  constructor(obj: LegMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): LegMill {
    const valuesMap: Record<string, typeof LegMill.values[number]> = {
      ['none']: 'Inga',
      ['left']: 'Vänster',
      ['right']: 'Höger',
      ['both']: 'Båda'
    }
    return new LegMill({
      value: input.items ? valuesMap[input.items[0]?.value] ?? 'Inga' : 'Inga'
    })
  }

  public static fromV2(input: IProdboardV2LegsAndSkirting): LegMill {
    const valuesMap: Record<string, typeof LegMill.values[number]> = {
      ['false_false']: 'Inga',
      ['true_false']: 'Vänster',
      ['false_true']: 'Höger',
      ['true_true']: 'Båda'
    }

    return new LegMill({
      value: valuesMap[[input.left_leg ?? false, input.right_leg ?? false].join('_')]
    })
  }
}

/**
 * Does the door have a handle to open it? It's a yes/no question.
 */
export class HandleDoorMill {
  value: 'Ja' | 'Nej'

  constructor(obj: HandleDoorMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): HandleDoorMill {
    return new HandleDoorMill({
      value: input.modificator?.toLowerCase()?.includes('vred') ? 'Ja' : 'Nej'
    })
  }

  public static fromV2(input: TProdboardV2HandleDoor): HandleDoorMill {
    const possibleTurningKnobs: string[] = [
      'Låsbolaget 676',
      'Magasinet 550'
    ]

    // TODO - Technically a "per-door" logic should be added for V2, since
    //  every door can have a different handle. However, for the first
    //  adaptation of V2 into Mill, we just see if there is ANY handle that
    //  is a turning knob. Darío - 2024-11-04
    const value = Object.keys(input).some(key =>
      possibleTurningKnobs.some(pos =>
        input[key].code?.includes(pos))) ? 'Ja' : 'Nej'
    return new HandleDoorMill({value: value})
  }
}

export class HiddenVisibleWidthMill {
  visible: number
  hidden: number

  constructor(obj: HiddenVisibleWidthMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any, previousValue: HiddenVisibleWidthMill | undefined): HiddenVisibleWidthMill {
    const data: HiddenVisibleWidthMill = previousValue ??
      new HiddenVisibleWidthMill({visible: 0, hidden: 0})

    if (input.code === 'visible width') {
      data.visible = +(input.value ?? 0)
    }
    if (input.code === 'hidden width') {
      data.hidden = +(input.value ?? 0)
    }

    return data
  }

  public static fromV2(input: number, totalWidth: number): HiddenVisibleWidthMill {
    return new HiddenVisibleWidthMill({
      visible: totalWidth - input,
      hidden: input
    })
  }
}

export class FanAdoptionMill {
  value: 'Ja' | 'Nej'

  constructor(obj: FanAdoptionMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): FanAdoptionMill {
    return new FanAdoptionMill({
      value: input.value?.name ?? 'Nej'
    })
  }

  public static fromV2(input: boolean): FanAdoptionMill {
    return {value: input ? 'Ja' : 'Nej'}
  }
}

export class BrassPlateMill {
  value: typeof BrassPlateMill.values[number]

  public static readonly values =
    ['Ingen', 'Vänster', 'Höger', 'Båda'] as const

  constructor(obj: BrassPlateMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): BrassPlateMill {
    const valuesMap: Record<string, typeof BrassPlateMill.values[number]> = {
      'None': 'Ingen',
      'Left': 'Vänster',
      'Right': 'Höger',
      'Both': 'Båda'
    }
    return new BrassPlateMill({
      value: valuesMap[input.value?.name] ?? 'Ingen'
    })
  }

  public static fromV2(input: TProdboardV2Drawers): BrassPlateMill {
    const valuesMap: Record<string, typeof BrassPlateMill.values[number]> = {
      'left': 'Vänster',
      'right': 'Höger',
      'both': 'Båda'
    }
    return new BrassPlateMill({
      value: valuesMap[input.brass_tag] ?? 'Ingen'
    })
  }
}

export class CombinedUnitMill {
  left: 'Ja' | 'Nej'
  right: 'Ja' | 'Nej'
  top: 'Ja' | 'Nej'
  bot: 'Ja' | 'Nej'

  constructor(obj: CombinedUnitMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): CombinedUnitMill {
    return new CombinedUnitMill({
      left: input?.value?.options?.combi_new?.left === true ? 'Ja' : 'Nej',
      right: input?.value?.options?.combi_new?.right === true ? 'Ja' : 'Nej',
      top: input?.value?.options?.combi_new?.top === true ? 'Ja' : 'Nej',
      bot: input?.value?.options?.combi_new?.bot === true ? 'Ja' : 'Nej'
    })
  }

  public static fromV2(input: IProdboardV2CombinedUnit): CombinedUnitMill {
    return new CombinedUnitMill({
      left: input.left === true ? 'Ja' : 'Nej',
      right: input.right === true ? 'Ja' : 'Nej',
      top: input.top === true ? 'Ja' : 'Nej',
      bot: input.bot === true ? 'Ja' : 'Nej'
    })
  }
}

/**
 * Different type of door, or door collection, that cabinet has. Usually,
 * every cabinet belongs to a line/collection of cabinets. All of those have
 * the same door style.
 */
export class DoorTypeMill {
  value: typeof DoorTypeMill.values[number]

  public static readonly values =
    ['Lucka Högalid', 'Lucka Djupadal', 'Lucka Rönneholm', 'Lucka Sunnanå',
      'Lucka Malmgården', 'Lucka Sorgenfri', 'Lucka Mellanheden'] as const

  constructor(obj: DoorTypeMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): DoorTypeMill {
    return new DoorTypeMill({
      value: input.collection?.name ?? ''
    })
  }

  public static fromV2(input: IProdboardV2DoorStyle): DoorTypeMill {
    const valuesMap: Record<string, typeof DoorTypeMill.values[number]> = {
      ['Lucka Hogalid']: 'Lucka Högalid',
      ['Lucka Högalid']: 'Lucka Högalid',
      ['Lucka Djupadal']: 'Lucka Djupadal',
      ['Lucka Ronneholm']: 'Lucka Rönneholm',
      ['Lucka Rönneholm']: 'Lucka Rönneholm',
      ['Lucka Sunnana']: 'Lucka Sunnanå',
      ['Lucka Sunnanå']: 'Lucka Sunnanå',
      ['Lucka Malmgarden']: 'Lucka Malmgården',
      ['Lucka Malmgården']: 'Lucka Malmgården',
      ['Lucka Sorgenfri']: 'Lucka Sorgenfri',
      ['Lucka Mellanheden']: 'Lucka Mellanheden'
    }
    return new DoorTypeMill({
      value: valuesMap[input.name] ?? 'Lucka Djupadal'
    })
  }
}

/**
 * Type of hinges that cabinet doors is using
 */
export class HingesMill {
  value: typeof HingesMill.values[number]

  public static readonly values = [
    '1. Klassiskt lyftgångjärn',
    '2. Moderna mjukstängande gångjärn',
    '3. Modernt gångjärn med synlig tapp'] as const

  constructor(obj: HingesMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): HingesMill {
    return new HingesMill({
      value: input?.value?.name ?? ''
    })
  }

  public static fromV2(input: TProdboardV2Hinges): HingesMill {
    const map: Record<TProdboardV2Hinges, typeof HingesMill.values[number]> = {
      'old_hinges': '1. Klassiskt lyftgångjärn',
      'blum_hinges': '2. Moderna mjukstängande gångjärn'
    }
    return new HingesMill({
      value: map[input] ?? '1. Klassiskt lyftgångjärn'
    })
  }
}

/**
 * Type of handle that a drawer can have. Even though, now days (2024-09-24)
 * this is considered an Appliance, not a Cabinet Option. But we keep this
 * mapping for retro-compatibility issues.
 */
export class HandleDrawerMill {
  value: string

  constructor(obj: HandleDrawerMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): HandleDrawerMill {
    return new HandleDrawerMill({
      value: input?.value?.handle?.dr_model?.dr_choice?.code ?? ''
    })
  }

  public static fromV2(): HandleDrawerMill {
    // It is now considered an Appliance, so we don't bother parsing it in V2.
    return new HandleDrawerMill({
      value: ''
    })
  }
}

/**
 * Shelves that can be found inside a cabinet. As fas as we know, it's usually
 * a minimum of 0, so none, and a maximum of 6.
 */
export class ShelvesMill {
  min: number
  max: number
  value: number

  constructor(obj: ShelvesMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): ShelvesMill {
    const matches = input?.value?.name
      ?.replace(/\s/g, '')
      ?.match(/def(\d)\(max(\d.*)\)/)
    return new ShelvesMill({
      min: matches?.length > 2 ? +matches[1] : 0,
      max: matches?.length > 2 ? +matches[2] : 6,
      value: input.value?.options?.sh?.qty ?? 0
    })
  }

  public static fromV2(input: IProdboardV2Shelves): ShelvesMill {
    return new ShelvesMill({
      min: 0, // It doesn't come, so we put the lowest minimum, 0.
      max: 20, // It doesn't come, so we just put a big maximum.
      value: input.q_shelves ?? 0
    })
  }
}

/**
 * Inside cabinet doors, specially (maybe only) for those that you "hang" from
 * walls, can have a special type of shelf on the doors with two rows of items,
 * one on top and one on the bottom. The "spice/jar rack".
 * If it is deep enough it is considered "jar rack". If it is a bit smaller
 * in depth, it is considered a "spice rack". However, there is an option
 * "combo" that represents a top shelf as "spice rack" and the bottom shelf
 * as "jar rack".
 */
export class SpiceRackMill {
  active: boolean
  doors: {
    pos: typeof SpiceRackMill.positions[number]
    type: typeof SpiceRackMill.types[number]
  }[]

  public static readonly positions =
    ['only', 'left', 'right', 'mid', 'top', 'bot',
      'top_left', 'top_mid', 'top_right',
      'bot_left', 'bot_mid', 'bot_right'] as const
  public static readonly types =
    ['Ingen', 'Kryddhylla', 'Burkhylla', 'Combo'] as const

  constructor(obj: SpiceRackMill) {
    Object.assign(this, obj)
  }

  /**
   * Now days, 27-09-2024, V0 config is coming in a different option name, so
   * we are never parsing it, we are always parsing V1. Summarising, this
   * method is useless and is never been used in reality, but we maintain it
   * just in case.
   * V1 name: "HYLLOR PÅ LUCKANS INSIDA"
   * V0 name: "HYLLOR PÅ LUCKANS INSIDA - ANVÄND EJ"
   */
  private static fromV0ToV1(input: any): any {
    const valuesMap: Record<string, string> = {
      'Nix': 'none',
      'Spice rack': 'spice',
      'Jar rack': 'jar',
      'Combo spice/jar': 'combo'
    }

    // Map left/only door to V1
    if (input.value?.options?.sh_door || input.value?.options?.sh_door_l) {
      if (input.value?.options?.sh_door) {
        input.value = {
          options: input.value.options,
          doors_setting: {
            door_1: {
              pos: 'only',
              type: {
                code: valuesMap[input.value.options.sh_door.type?.name] ?? 'none'
              }
            }
          }
        }
      }
      if (input.value?.options?.sh_door_l) {
        input.value = {
          options: input.value.options,
          doors_setting: {
            door_1: {
              pos: 'left',
              type: {
                code: valuesMap[input.value.options.sh_door_l.type?.name] ?? 'none'
              }
            }
          }
        }
      }
    }
    // Map right door to V1
    if (input.value?.options?.sh_door_r) {
      input.value = {
        options: input.value.options,
        doors_setting: {
          door_1: input.value.doors_setting?.door_1,
          door_2: {
            pos: 'right',
            type: {
              code: valuesMap[input.value.options.sh_door_r.type?.name] ?? 'none'
            }
          }
        }
      } as any
    }
    return input
  }

  public static fromV1(input: any): SpiceRackMill {
    const valuesMap: Record<string, typeof SpiceRackMill.types[number]> = {
      'none': 'Ingen',
      'spice': 'Kryddhylla',
      'jar': 'Burkhylla',
      'combo': 'Combo'
    }

    // Make sure V0 is mapped to V1 first
    input = this.fromV0ToV1(input)

    input.value = {doors_setting: {}, ...input.value}

    return new SpiceRackMill({
      active: input.value?.code !== 'off',
      doors: Object.keys(input.value.doors_setting)
        // Make sure it sorts like: door_1, door_2, etc.
        .sort((a, b) => a.localeCompare(b))
        .map((key, index) => ({
          pos: input.value.doors_setting[key].pos ??
            (index === 0 ? 'left' : 'right'),
          type: valuesMap[input.value.doors_setting[key]?.type?.name] ?? 'Ingen'
        }))
    })
  }

  public static fromV2(input: TProdboardV2SpiceRacks, hasTwoRows: boolean): SpiceRackMill {
    // TODO - Right now PB is not sending a door key, "1.2" or any other, if
    //  there is no item selected. However, whenever any door has spice racks,
    //  the rest of doors should be marked with value "Ingen". Otherwise we
    //  cannot assure, at least not in a certain way, how many door there
    //  should be, we only know about the doors that are set up.

    // Positions used in wall cabinets:
    //  OD1   -> 1
    //  OD1x2 -> 1 | 2
    //  OD2   -> 1_left | 1_right
    //  OD2x2 -> 1_left | 1_right || 2_left | 2_right
    //  OD3   -> 1.1_left | 1.1_right | 1.2
    //  OD3x2 -> 1.1 | 1.2 | 1.3 || 2.1 | 2.2 | 2.3
    const positionsMap: Record<string, typeof SpiceRackMill.positions[number]> = {
      '1': hasTwoRows ? 'top' : 'only',
      '1.1': 'top_left',
      '1.1_left': 'left',
      '1.1_right': 'mid',
      '1.2': hasTwoRows ? 'top_mid' : 'right',
      '1.3': 'top_right',
      '1_left': hasTwoRows ? 'top_left' : 'left',
      '1_right': hasTwoRows ? 'top_right' : 'right',
      '2': 'bot',
      '2.1': 'bot_left',
      '2.2': 'bot_mid',
      '2.3': 'bot_right',
      '2_left': 'bot_left',
      '2_right': 'bot_right'
    }

    return new SpiceRackMill({
      active: true,
      doors: Object.keys(input)
        .filter(key => !!input[key].value)
        .map(key => {
          const doorValue = input[key].value!
          return {
            pos: positionsMap[key],
            type: doorValue
          }
        })
    })
  }
}

export class HangingMill {
  value: typeof HangingMill.values[number]

  public static readonly values = [
    'undef', 'left', 'right', 'top'
  ] as const

  constructor(obj: HangingMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): HangingMill {
    if (input.value?.options?.open?.type === true) {
      input.value.options.open.type = {code: 'top'}
    }
    return new HangingMill({
      value: input.value?.options?.open?.type?.code ?? 'left'
    })
  }

  public static fromV2(input: IProdboardV2Hanging, isLiftArm: boolean): HangingMill {
    const value = isLiftArm ? 'top' : input.value
    return new HangingMill({
      value: value ?? 'left'
    })
  }
}

/**
 * Cabinets can have lights inside/under them, and they can be LEDs in a line
 * or some type of spotlight.
 */
export class LightingMill {
  active: boolean
  value: typeof LightingMill.values[number]

  public static readonly values = [
    'Ingen belysning',
    'Spotlights undertill',
    'LED-strips undertill',
    'Spotlights undertill, och LED-strips inuti skåpet',
    'LED-strips undertill och inuti skåpet',
    'LED-strips inuti skåpet'
  ] as const

  constructor(obj: LightingMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): LightingMill {
    return new LightingMill({
      active: input.value?.name === 'Ja',
      value: input.value?.options?.light?.choice?.name ?? 'Ingen belysning'
    })
  }

  public static fromV2(input: string): LightingMill {
    const valuesMap: Record<string, typeof LightingMill.values[number]> = {
      'spotlights_underneath': 'Spotlights undertill',
      'ledstrips_underneath': 'LED-strips undertill',
      'spotlights_underneath_ledstrips_inside': 'Spotlights undertill, och LED-strips inuti skåpet',
      'led_strips_underneath_and_inside': 'LED-strips undertill och inuti skåpet',
      'ledstrips_inside': 'LED-strips inuti skåpet'
    }

    return new LightingMill({
      active: true,
      value: valuesMap[input] ?? 'Ingen belysning'
    })
  }
}

/**
 * It indicates if cabinet has a cornice or not. What's a cornice? It's like
 * a crown for wall-hanging cabinets. It's a finishing piece that goes on top
 * of the cabinet surrounding it from left, right and front. Nothing in the
 * back side. I (Darío) believe it's meant to be decorative and useful when
 * your cabinets are touching the ceiling, so no little gap between cabinet and
 * ceiling.
 * It will add some extra height to the cabinet.
 *  ╮──────────────────╭  <-- Cornice
 *  ├──────────────────┤  <-- Top of cabinet
 */
export class CorniceMill {
  value: 'Ja' | 'Nej'

  constructor(obj: CorniceMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): CorniceMill {
    return new CorniceMill({
      value: input.value?.options?.cornice?.mode === true ? 'Ja' : 'Nej'
    })
  }

  public static fromV2(input: boolean): CorniceMill {
    return new CorniceMill({
      value: input === true ? 'Ja' : 'Nej'
    })
  }
}

/**
 * When cabinet is big enough it might have, or not, a center post dividing
 * the cabinet. This represents the type of middle post it is.
 */
export class CenterPostMill {
  value: typeof CenterPostMill.values[number]

  public static readonly values = [
    'Fast mittstolpe mellan luckorna',
    'Mittstolpen är fixerad vid en av luckorna',
    'Ingen mittstolpe mellan luckorna'
  ] as const

  constructor(obj: CenterPostMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): CenterPostMill {
    return new CenterPostMill({
      value: input.value?.name
    })
  }

  public static fromV2(input: IProdboardV2CenterPost): CenterPostMill {
    const valuesMap: Record<string, typeof CenterPostMill.values[number]> = {
      'off': 'Ingen mittstolpe mellan luckorna',
      'default': 'Fast mittstolpe mellan luckorna',
      'fake': 'Mittstolpen är fixerad vid en av luckorna'
    }

    return new CenterPostMill({
      value: valuesMap[input.type] ?? 'Fast mittstolpe mellan luckorna'
    })
  }
}

/**
 * Type of painting process that a cabinet can have
 */
export class PaintProcessMill {
  value: typeof PaintProcessMill.values[number]

  public static readonly values = [
    '1. Linoljefärg, komplett',
    '2. Linoljefärg, grundmålning',
    '3. Vanlig färg, komplett',
    '4. Omålat på utsidorna, vaxat på insidorna',
    '5. Helt omålat och obehandlat',
    '6. Vanlig färg, grundmålning',
    '7. Linoljefärg, slutmålat i snickeriet',
    '8. Vanlig färg, slutmålat i snickeriet'
  ] as const

  constructor(obj: PaintProcessMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): PaintProcessMill {
    return new PaintProcessMill({
      value: input.value?.name
    })
  }

  public static fromV2(input: IProdboardV2PaintProcess): PaintProcessMill {
    return new PaintProcessMill({
      value: input.name ?? '4. Omålat på utsidorna, vaxat på insidorna'
    })
  }
}

/**
 * Type of front facade for the drawers of the cabinet.
 */
export class DrawerFrontMill {
  value: 'Standard' | 'Släta lådfronter'

  public static readonly values = [
    'Standard',
    'Släta lådfronter'
  ] as const

  constructor(obj: DrawerFrontMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): DrawerFrontMill {
    const valuesMap: Record<string, typeof DrawerFrontMill.values[number]> = {
      'Off': 'Standard',
      ['Släta lådfronter']: 'Släta lådfronter'
    }

    return new DrawerFrontMill({
      value: valuesMap[input.value?.name] ?? 'Standard'
    })
  }

  public static fromV2(input: IProdboardV2DoorStyle): DrawerFrontMill {
    return new DrawerFrontMill({
      value: input.smooth_facade === 'Släta lådfronter' ?
        'Släta lådfronter' : 'Standard'
    })
  }
}

/**
 * Curvy decorative pieces that go under the cabinet, in the front part.
 * They are little concave arches similar to this:
 *  ╞═══╤═════╤═══╡ <-- Base of cabinet
 *  │L ╱       ╲ R│ <-- Carpenter joy
 *  │╱           ╲│
 */
export class CarpenterJoyMill {
  active: boolean
  left: 'Ja' | 'Nej'
  right: 'Ja' | 'Nej'

  constructor(obj: CarpenterJoyMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): CarpenterJoyMill {
    return new CarpenterJoyMill({
      active: input.value?.code !== 'Off',
      left: input.value?.options?.frames?.fr_left === true ? 'Ja' : 'Nej',
      right: input.value?.options?.frames?.fr_right === true ? 'Ja' : 'Nej'
    })
  }

  public static fromV2(left: boolean, right: boolean, previousValue: CarpenterJoyMill | undefined): CarpenterJoyMill {
    const value = previousValue ?? new CarpenterJoyMill({
      active: true,
      left: 'Nej',
      right: 'Nej'
    })

    value.left = (value.left === 'Ja' || left) ? 'Ja' : 'Nej'
    value.right = (value.right === 'Ja' || right) ? 'Ja' : 'Nej'

    return value
  }
}

/**
 * Sockel or Skirting is a piece that goes below the cabinet. Only available
 * in standing cabinets. It covers the space between cabinet and floor. It's
 * basically the same as cornice for wall-hanging cabinets but in the bottom.
 * It can be a default skirting or a more decorative one. It looks like this:
 *  ├──────────────────┤ <-- Bottom of cabinet
 *  ╯──────────────────╰ <-- Skirting & floor
 */
export class SkirtingMill {
  value: typeof SkirtingMill.values[number]

  public static readonly values =
    ['Standardsockel (inskjuten)', 'Utanpåliggande sockellist'] as const

  constructor(obj: SkirtingMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): SkirtingMill {
    const valuesMap: Record<string, typeof SkirtingMill.values[number]> = {
      'reg': 'Standardsockel (inskjuten)',
      'sk': 'Utanpåliggande sockellist'
    }
    return new SkirtingMill({
      value: valuesMap[input.value?.options?.plinth?.type]
        ?? 'Standardsockel (inskjuten)'
    })
  }

  public static fromV2(input: IProdboardV2LegsAndSkirting): SkirtingMill {
    const valuesMap: Record<string, typeof SkirtingMill.values[number]> = {
      '1. Regular plinth': 'Standardsockel (inskjuten)',
      '2. Outside skirting': 'Utanpåliggande sockellist'
    }
    return new SkirtingMill({
      value: valuesMap[input.value] ?? 'Standardsockel (inskjuten)'
    })
  }
}

/**
 * Shelves of a cabinet can be adjustable or not.
 */
export class ShelvesAdjustableMill {
  value: 'Ja' | 'Nej'

  constructor(obj: ShelvesAdjustableMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): ShelvesAdjustableMill {
    return new ShelvesAdjustableMill({
      value: input.value?.name ?? 'Nej'
    })
  }

  public static fromV2(input: IProdboardV2Shelves): ShelvesAdjustableMill {
    return new ShelvesAdjustableMill({
      value: input.shelves_type === 'adjustable' ? 'Ja' : 'Nej'
    })
  }
}

/**
 * Door of a cabinet can be standar, glass or no door at all.
 * If there is no door, the inside can be painted or not. Just in case you
 * want it to look pretty once there is no door of course.
 */
export class DoorMill {
  value: typeof DoorMill.values[number]
  /**
   * Only present if, or better, only care if value is 'Ingen lucka'.
   */
  paintedInside?: 'Ja' | 'Nej'

  public static readonly values = [
    'Ingen lucka', 'Standardlucka', 'Vitrinlucka'
  ] as const

  constructor(obj: DoorMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): DoorMill {
    const valuesMap: Record<string, typeof DoorMill.values[number]> = {
      ['Välj bort lucka']: 'Ingen lucka',
      'Standardlucka': 'Standardlucka',
      'Vitrinlucka': 'Vitrinlucka'
    }

    return new DoorMill({
      value: valuesMap[input.value?.name] ?? 'Standardlucka',
      paintedInside: input.value?.door?.no_door?.option?.name === 'Målad insida'
        ? 'Ja' : 'Nej'
    })
  }

  public static fromV2(input: IProdboardV2Door): DoorMill {
    // TODO - In the future KDL wants this to be a "per-door" logic, but
    //  current version of Mill cannot support that. This is why we will get
    //  the first door and assume that all are of this type. It will need to
    //  be changed. Darío - 2024-11-06.
    const doorType = Object.values(input.doors)
      .filter(Boolean)
      .map(d => d.value)[0]
    return new DoorMill({
      value: (!input.hasDoor || !doorType) ? 'Ingen lucka' : doorType,
      paintedInside: input.isPaintedInside ? 'Ja' : 'Nej'
    })
  }
}

export class DoorAttachmentMill {
  value: 'Door on door' | 'Slides' | 'TBD'

  constructor(obj: DoorAttachmentMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): DoorAttachmentMill {
    return new DoorAttachmentMill({
      value: input.value?.name
    })
  }

  public static fromV2(input: IProdboardV2DoorAttachment): DoorAttachmentMill {
    return new DoorAttachmentMill({
      value: input.value
    })
  }
}

/**
 * The purpose of "överdimensionering" is to make some cabinets larger, to
 * create a buffer when the dimensions in the room are not 100% certain.
 * Customers almost always live in older houses, where the floors/walls/ceilings
 * never are 100% straight. So, therefore we add this "buffer", which are
 * 40mm more of extra material. This extra part will be cut off on location,
 * when the kitchen is fitted.
 * An option that is only configurable if you are admin in Prodboard.
 */
export class ScribingsMill {
  value: 'Ingen överdimensionering' |
    'Vänster' |
    'Höger' |
    'Både höger och vänster'

  constructor(obj: ScribingsMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): ScribingsMill {
    const data: ScribingsMill = new ScribingsMill({
      value: 'Ingen överdimensionering'
    })

    if (input.value?.options?.admin_frames?.left &&
      input.value?.options?.admin_frames?.right) {
      data.value = 'Både höger och vänster'
    } else if (input.value?.options?.admin_frames?.left) {
      data.value = 'Vänster'
    } else if (input.value?.options?.admin_frames?.right) {
      data.value = 'Höger'
    }

    return data
  }

  public static fromV2(input: any): ScribingsMill {
    return input
  }
}

/**
 * Basically the sides of the cabinet, which can have different styling,
 * side-padding, bottom-padding, and if set up, a "fancy console".
 *   Styling: It can be w/o anything, a smooth surface or a decorative one.
 *   Side-padding: If > 0 there will be a space between cabinet's frame and
 * "inside box". Basically, the bigger it is, the smaller the inside box is.
 *   Bottom-padding: Only present if styling is different from "w/o anything".
 * If > 0, there will be an "overflow" of side respecting to the cabinet. It
 * makes the total height of the cabinet bigger, but it doesn't affect the
 * "inside box".
 *   Console: Only present if styling is different from "w/o anything".
 * Piece attached to the bottom sides of the cabinet
 * (left, right or both) and to the wall. Console can be convex or concave.
 * Also, its height is configurable.
 * And looks like this:
 * ╞════╡ <-- Base of cabinet (cabinet is sideways)
 * │   ╭╯ <-- Console
 * │  ╱
 * │╱
 */
export class CoverSideMill {
  sides: ICoverSideSideMill[]

  public static readonly sides = ['left', 'right'] as const
  public static readonly styles = [
    'Ingen täcksida', 'Täcksida, slät', 'Täcksida med spegel'
  ] as const
  public static readonly consoles = [
    'Utan konsoll', 'Med konsol (konvex)', 'Med konsol (konkav)'
  ] as const

  constructor(obj: CoverSideMill) {
    Object.assign(this, obj)
  }

  private static staticFromV0ToV1(input: any): any {
    if (input.value?.options?.left_panel?.depth) {
      input.value.options.left_panel.offset =
        input.value?.options?.left_panel?.depth
    }
    if (input.value?.options?.right_panel?.depth) {
      input.value.options.right_panel.offset =
        input.value?.options?.right_panel?.depth
    }
    return input
  }

  public static fromV1(input: any, previousValue: CoverSideMill | undefined): CoverSideMill {
    const value: CoverSideMill = previousValue ??
      new CoverSideMill({sides: []})

    // Make sure V0 is changed to V1
    input = this.staticFromV0ToV1(input)

    if (input.name?.includes('höger')) {
      value.sides.push({
        side: 'right',
        style: input.value?.name ?? 'Ingen täcksida',
        paddingBottom: input.value?.options?.right_panel?.offset ?? 0,
        console: input.value?.options?.side_panels?.right_side?.name ?? 'Utan konsoll'
      })
    }
    if (input.name?.includes('vänster')) {
      value.sides.push({
        side: 'left',
        style: input.value?.name ?? 'Ingen täcksida',
        paddingBottom: input.value?.options?.left_panel?.offset ?? 0,
        console: input.value?.options?.side_panels?.left_side?.name ?? 'Utan konsoll'
      })
    }
    return value
  }

  public static fromV2(input: IProdboardV2CoverSide): CoverSideMill {
    const consoleMap: Record<string, typeof CoverSideMill.consoles[number]> = {
      'console_concave': 'Med konsol (konkav)',
      'console_convex': 'Med konsol (konvex)'
    }
    const styleMap: Record<string, typeof CoverSideMill.styles[number]> = {
      ['Ingen täcksida']: 'Ingen täcksida',
      ['Täcksida med spegel']: 'Täcksida med spegel',
      ['Slät täcksida']: 'Täcksida, slät'
    }

    return new CoverSideMill({
      sides: input.sides?.map(s => ({
        side: s.side ?? 'left',
        style: styleMap[s.style] ?? 'Ingen täcksida',
        paddingBottom: s.down_offset ?? 0,
        console: consoleMap[s.console] ?? 'Utan konsoll',
        consoleHeight: s.console_height
      })) ?? []
    })
  }
}

export interface ICoverSideSideMill {
  side: typeof CoverSideMill.sides[number]
  style: typeof CoverSideMill.styles[number]
  paddingBottom: number
  console?: typeof CoverSideMill.consoles[number]
  consoleHeight?: number
}

/**
 * Same as CoverSide but way simpler. It's for the back side, and it only has
 * a configurable style, nothing else.
 */
export class BackPanelMill {
  style: typeof BackPanelMill.styles[number]

  public static readonly styles = [
    'Ingen täcksida', 'Slät täcksida', 'Täcksida med spegel'
  ] as const

  constructor(obj: BackPanelMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): BackPanelMill {
    return new BackPanelMill({
      style: input.value?.name ?? 'Ingen täcksida'
    })
  }

  public static fromV2(input: IProdboardV2BackPanel): BackPanelMill {
    const styleMap: Record<string, typeof BackPanelMill.styles[number]> = {
      ['Ingen täcksida']: 'Ingen täcksida',
      ['Täcksida med spegel']: 'Täcksida med spegel',
      ['Slät täcksida']: 'Slät täcksida'
    }

    return new BackPanelMill({
      style: styleMap[input.style] ?? 'Ingen täcksida'
    })
  }
}

export class HiddenDrawerSinkMill {
  value: typeof HiddenDrawerSinkMill.values[number]

  public static readonly values = [
    'Ingen utdragslåda',
    'En extra utdragslåda',
    'Två extra utdragslådor'
  ] as const

  constructor(obj: HiddenDrawerSinkMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): HiddenDrawerSinkMill {
    const valuesMap: Record<string, typeof HiddenDrawerSinkMill.values[number]> = {
      ['En utdragslåda']: 'En extra utdragslåda',
      ['En utdragslådor']: 'En extra utdragslåda',
      ['En extra utdragslåda']: 'En extra utdragslåda',
      ['Två utdragslådor']: 'Två extra utdragslådor',
      ['Två extra utdragslådor']: 'Två extra utdragslådor'
    }

    return new HiddenDrawerSinkMill({
      value: valuesMap[input.value?.name] ?? 'Ingen utdragslåda'
    })
  }

  public static fromV2(input: TProdboardV2Drawers): HiddenDrawerSinkMill {
    const hiddenDrawers = Object.keys(input)
      .filter(key => key.includes('hidden_dr'))
      .reduce(acc => acc + 1, 0)
    const valuesMap: Record<number, typeof HiddenDrawerSinkMill.values[number]> = {
      0: 'Ingen utdragslåda',
      1: 'En extra utdragslåda',
      2: 'Två extra utdragslådor'
    }

    return new HiddenDrawerSinkMill({
      value: valuesMap[hiddenDrawers] ?? 'Ingen utdragslåda'
    })
  }
}

/**
 * Built in cutting board that a cabinet can have.
 */
export class CuttingBoardMill {
  value: 'Ja' | 'Nej'

  constructor(obj: CuttingBoardMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): CuttingBoardMill {
    return new CuttingBoardMill({
      value: input.value?.options?.ch_b?.on === true ? 'Ja' : 'Nej'
    })
  }

  public static fromV2(input: boolean): CuttingBoardMill {
    return new CuttingBoardMill({
      value: input === true ? 'Ja' : 'Nej'
    })
  }
}

export class DrawerDoorMill {
  value: 'Lucka' | 'Låda'

  constructor(obj: DrawerDoorMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): DrawerDoorMill {
    return new DrawerDoorMill({
      value: input.value?.name ?? 'Lucka'
    })
  }

  // This option does not come in V2 anymore.
}

export class PaintSideMill {
  value: typeof PaintSideMill.values[number]

  public static readonly values =
    ['Ingen', 'Vänster', 'Höger', 'Båda'] as const

  constructor(obj: PaintSideMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any): PaintSideMill {
    const valuesMap: Record<string, typeof PaintSideMill.values[number]> = {
      'None': 'Ingen',
      'Left': 'Vänster',
      'Right': 'Höger',
      'Left and Right': 'Båda'
    }
    return new PaintSideMill({
      value: valuesMap[input.value?.name] ?? 'Ingen'
    })
  }

  public static fromV2(input: IProdboardV2PaintSide): PaintSideMill {
    const count = Object.keys(input)
      .filter(key => input[key] === true)
      .reduce(acc => acc + 1, 0)
    const valuesMap: Record<number, typeof PaintSideMill.values[number]> = {
      0: 'Ingen',
      1: input.left ? 'Vänster' : 'Höger',
      2: 'Båda'
    }

    return new PaintSideMill({
      value: valuesMap[count] ?? 'Ingen'
    })
  }
}

export interface IFanExtractorPanelSideMill {
  side: typeof FanExtractorPanelMill.sides[number]
  style: typeof FanExtractorPanelMill.styles[number]
  console?: typeof FanExtractorPanelMill.consoles[number]
}

/**
 * There is a special cabinet that is used to cover a fan extractor, like
 * a chimney more or less. Each of the panels of that cabinet are configured
 * individually, and this is the model that represents those panel's setup.
 * The configuration is very similar to CoverSide. You can set up styling and
 * console.
 *   Styling: It can be w/o anything, a smooth surface or a decorative one.
 *   Console: Only present if styling is different from "w/o anything". And
 * only available on the sides, not in the front. Piece attached to the bottom
 * sides of the cabinet (left, right or both) and to the wall.
 * Console can be convex or concave. Also, its height is configurable.
 * And looks like this:
 * ╞════╡ <-- Base of cabinet (cabinet is sideways)
 * │   ╭╯ <-- Console
 * │  ╱
 * │╱
 */
export class FanExtractorPanelMill {
  sides: IFanExtractorPanelSideMill[]

  public static readonly sides =
    [...CoverSideMill.sides, 'front'] as const
  public static readonly styles =
    ['No coverside', 'Plain', 'Mirror'] as const
  public static readonly consoles =
    ['No console', 'With console (concave)', 'With console (convex)'] as const

  constructor(obj: FanExtractorPanelMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any, previousValue: FanExtractorPanelMill | undefined): FanExtractorPanelMill {
    const value = previousValue ??
      new FanExtractorPanelMill({sides: []})

    const styleMap: Record<string, typeof FanExtractorPanelMill.styles[number]> = {
      'med spegel': 'Mirror',
      ['omålad']: 'No coverside',
      ['slät']: 'Plain'
    }
    const consoleMap: Record<string, typeof FanExtractorPanelMill.consoles[number]> = {
      'short': 'No console',
      'long': 'With console (convex)',
      'long_2': 'With console (concave)'
    }

    if (input.name?.includes('höger')) {
      value.sides.push({
        side: 'right',
        style: styleMap[input.value?.name?.toLowerCase()] ?? 'No coverside',
        console: consoleMap[input.value?.options?.side_panels?.right_side?.code] ?? 'No console'
      })
    }
    if (input.name?.includes('vänster')) {
      value.sides.push({
        side: 'left',
        style: styleMap[input.value?.name?.toLowerCase()] ?? 'No coverside',
        console: consoleMap[input.value?.options?.side_panels?.left_side?.code] ?? 'No console'
      })
    }
    if (input.name?.includes('front')) {
      value.sides.push({
        side: 'front',
        style: styleMap[input.value?.name?.toLowerCase()] ?? 'No coverside'
      })
    }

    return value
  }

  public static fromV2(input: string): FanExtractorPanelMill {
    const valuesMap: Record<string, typeof FanExtractorPanelMill.styles[number]> = {
      'without': 'No coverside',
      'smooth': 'Plain',
      'facade': 'Mirror'
    }

    return new FanExtractorPanelMill({
      sides: [{
        side: 'front',
        style: valuesMap[input] ?? 'No coverside',
        console: 'No console'
      }]
    })
  }

  public static getStyleFromCoverSideStyle(
    style: typeof CoverSideMill.styles[number]
  ): typeof FanExtractorPanelMill.styles[number] {
    const map: Record<typeof CoverSideMill.styles[number], typeof FanExtractorPanelMill.styles[number]> = {
      ['Ingen täcksida']: 'No coverside',
      ['Täcksida, slät']: 'Plain',
      ['Täcksida med spegel']: 'Mirror'
    }
    return map[style]
  }

  public static getConsoleFromCoverSideConsole(
    console: typeof CoverSideMill.consoles[number]
  ): typeof FanExtractorPanelMill.consoles[number] {
    const map: Record<typeof CoverSideMill.consoles[number], typeof FanExtractorPanelMill.consoles[number]> = {
      ['Utan konsoll']: 'No console',
      ['Med konsol (konvex)']: 'With console (convex)',
      ['Med konsol (konkav)']: 'With console (concave)'
    }
    return map[console]
  }
}

/**
 * Different divisions that a drawer can have. And each of the different types
 * will have an amount of how many of those are present. For example, a drawer
 * can have 3 cutlery dividers, a knife block and a wavy.
 */
export class DrawerInsertMill {
  cutleryDiv: number
  knifeBlock: number
  wavy: number

  constructor(obj: DrawerInsertMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any, previousValue: DrawerInsertMill | undefined): DrawerInsertMill {
    const value = previousValue ??
      new DrawerInsertMill({cutleryDiv: 0, knifeBlock: 0, wavy: 0})

    if (input.value?.options?.div_wall) {
      value.cutleryDiv += Object.keys(input.value.options.div_wall).length
    }
    if (input.value?.options?.knife) {
      value.knifeBlock += Object.keys(input.value.options.knife).length
    }
    if (input.value?.options?.wavy) {
      value.wavy += Object.keys(input.value.options.wavy).length
    }

    return value
  }

  public static fromV2(input: TProdboardV2Drawers, previousValue: DrawerInsertMill | undefined): DrawerInsertMill {
    // TODO - Also, this is supposed to change from a counter to a proper
    //  cutlery tray model mapping, which is not in V1, so we are not doing it
    //  as of now. Darío - 2024-11-04
    const value = previousValue ??
      new DrawerInsertMill({cutleryDiv: 0, knifeBlock: 0, wavy: 0})

    Object.keys(input)
      .filter(drawerKey => DrawerInsertPossibilitiesV2
        .some(possibility =>
          new RegExp(possibility, 'i').exec(drawerKey) !== null))
      .forEach(drawerKey => {
        const drawerValue = input[drawerKey]
        if (drawerValue.wavy_block) {
          value.wavy++
        }
        if (drawerValue.cutlery_tray) {
          value.cutleryDiv++
          if (drawerValue.cutlery_tray.includes('BK')) {
            value.knifeBlock++
          }
        }
      })

    return value
  }
}

type THiddenDrawerMill = { [key in typeof HiddenDrawerMill.types[number]]: number }

/**
 * Naming is confusing as hell, but it basically indicates the contents of
 * a cabinet. A cabinet can contain big drawers, small drawers, shelves or
 * nothing of course. In here we count how many of those possibilities are
 * inside the cabinet
 */
export class HiddenDrawerMill implements THiddenDrawerMill {
  smallDrawers: number
  bigDrawers: number
  shelves: number

  public static readonly types =
    ['smallDrawers', 'bigDrawers', 'shelves'] as const

  constructor(obj: HiddenDrawerMill) {
    Object.assign(this, obj)
  }

  public static fromV1(input: any, previousValue: HiddenDrawerMill | undefined): HiddenDrawerMill {
    const value = previousValue ??
      new HiddenDrawerMill({smallDrawers: 0, bigDrawers: 0, shelves: 0})

    const valuesMap: Record<string, typeof HiddenDrawerMill.types[number]> = {
      ['Utdragslåda, låg']: 'smallDrawers',
      ['Utdragslåda, hög']: 'bigDrawers',
      ['Hyllplan']: 'shelves'
    }

    if (input.value?.name && !input.value.name.includes('Inget') && valuesMap[input.value.name]) {
      value[valuesMap[input.value.name]]++
    }

    return value
  }

  public static fromV2(input: TProdboardV2Filling, previousValue: HiddenDrawerMill | undefined): HiddenDrawerMill {
    const value = previousValue ??
      new HiddenDrawerMill({smallDrawers: 0, bigDrawers: 0, shelves: 0})

    input.forEach(elements => {
      const smallHiddenDrawers = Object.keys(elements)
        .filter(key => elements[key].code === 'mix_drawer')
        .filter(key => elements[key].h_drawer <= 100)
        .reduce(acc => acc + 1, 0)
      value.smallDrawers += smallHiddenDrawers

      const bigHiddenDrawers = Object.keys(elements)
        .filter(key => elements[key].code === 'mix_drawer')
        .filter(key => elements[key].h_drawer > 100)
        .reduce(acc => acc + 1, 0)
      value.bigDrawers += bigHiddenDrawers

      const shelves = Object.keys(elements)
        .filter(key => elements[key].code === 'mix_shelf')
        .reduce(acc => acc + 1, 0)
      value.shelves += shelves
    })

    return value
  }
}

export const ALL_MILL_ITEM_OPTIONS: IMillItemOptions = {
  backPanel: new BackPanelMill({style: 'Ingen täcksida'}),
  brassPlate: new BrassPlateMill({value: 'Ingen'}),
  carpenterJoy: new CarpenterJoyMill({
    right: 'Nej',
    left: 'Nej',
    active: false
  }),
  centerPost: new CenterPostMill({value: 'Fast mittstolpe mellan luckorna'}),
  combinedUnit: new CombinedUnitMill({
    left: 'Nej',
    top: 'Nej',
    right: 'Nej',
    bot: 'Nej'
  }),
  cornice: new CorniceMill({value: 'Nej'}),
  coverSide: new CoverSideMill({
    sides: [{
      side: 'left',
      console: 'Utan konsoll',
      style: 'Ingen täcksida',
      paddingBottom: 0
    }]
  }),
  cuttingBoard: new CuttingBoardMill({value: 'Nej'}),
  door: new DoorMill({value: 'Standardlucka'}),
  doorAttachment: new DoorAttachmentMill({value: 'TBD'}),
  doorType: new DoorTypeMill({value: 'Lucka Djupadal'}),
  drawerDoor: new DrawerDoorMill({value: 'Lucka'}),
  drawerFront: new DrawerFrontMill({value: 'Standard'}),
  drawerInsert: new DrawerInsertMill({wavy: 0, cutleryDiv: 0, knifeBlock: 0}),
  fanAdoption: new FanAdoptionMill({value: 'Nej'}),
  fanExtractorPanel: new FanExtractorPanelMill({
    sides: [{
      side: 'front',
      style: 'No coverside',
      console: 'No console'
    }]
  }),
  filler: new FillerMill({left: 0, right: 0, bottom: 0, top: 0}),
  frameWidth: new FrameWidthMill({left: 0, right: 0, bottom: 0, top: 0}),
  handleDoor: new HandleDoorMill({value: 'Nej'}),
  handleDrawer: new HandleDrawerMill({value: ''}),
  hanging: new HangingMill({value: 'left'}),
  hiddenDrawer: new HiddenDrawerMill({
    bigDrawers: 0,
    smallDrawers: 0,
    shelves: 0
  }),
  hiddenDrawerSink: new HiddenDrawerSinkMill({value: 'Ingen utdragslåda'}),
  hiddenVisibleWidth: new HiddenVisibleWidthMill({visible: 600, hidden: 600}),
  hinges: new HingesMill({value: '1. Klassiskt lyftgångjärn'}),
  legs: new LegMill({value: 'Inga'}),
  lighting: new LightingMill({value: 'Ingen belysning', active: false}),
  paintProcess: new PaintProcessMill({value: '1. Linoljefärg, komplett'}),
  paintSide: new PaintSideMill({value: 'Ingen'}),
  scribings: new ScribingsMill({value: 'Ingen överdimensionering'}),
  shelves: new ShelvesMill({value: 0, max: 10, min: 0}),
  shelvesAdjustable: new ShelvesAdjustableMill({value: 'Nej'}),
  skirting: new SkirtingMill({value: 'Standardsockel (inskjuten)'}),
  spiceRack: new SpiceRackMill({doors: [], active: false})
}
